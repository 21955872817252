import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <div className="columns is-centered">
      <div className="column is-4 section-404">
        <h1 className="is-size-3">Page Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <Link className="button download-resume" to="/">Back to Home</Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
